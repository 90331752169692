<template>
  <div class="smart_factory_content">
    <Header @link-jump="jump" :isChange="isChange"></Header>
    <div id="bannerImg" class="banner">
      <!-- <img src="../assets/smartFactory/header.png" alt="" /> -->
      <img src="https://oss-gongsi.zuoshang2019.com/gongsi/header5.png" alt="智慧工厂" />
    </div>
    <div id="energy" class="smart_factory">
      <h2 class="title">能源管理解决方案</h2>
      <h4 class="subtitle">
        智慧工厂涵盖了能源管理、砂石骨料一卡通、设备管理、车辆调度等多个领域
      </h4>
      <div class="case">
        <div class="case_introduce">
          <div>
            <div class="detail">
              <h3 class="detail_title">能源管理</h3>
              <h4 class="detail_subtitle">Energy management system</h4>
              <h4 class="detail_list_title">能源管理解决方案</h4>
              <div class="list">
                <div class="item">
                  <div class="dot_big">
                    <div class="dot_small"></div>
                  </div>
                  <div>电耗、煤耗、气耗、水耗等综合监测；</div>
                </div>
                <div class="item">
                  <div class="dot_big dot_big_1">
                    <div class="dot_small"></div>
                  </div>
                  <div>针对工厂工艺各个环节，进行能耗评比；</div>
                </div>
                <div class="item">
                  <div class="dot_big dot_big_2">
                    <div class="dot_small"></div>
                  </div>
                  <div>通过在线实时监测，反向诊断高能耗点位；</div>
                </div>
                <div class="item">
                  <div class="dot_big dot_big_3">
                    <div class="dot_small"></div>
                  </div>
                  <div>融入碳排放量计算，对整体能耗进行有效管理。</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="case_img">
          <img src="../assets/smartFactory/factory.png" alt="智慧工厂" />
        </div>
      </div>
      <!-- <div class="btn">
        <button type="button">更多解决方案</button>
      </div> -->
    </div>
    <div id="sand" class="smart_factory smart_city">
      <h2 class="title">砂石骨料一卡通解决方案</h2>
      <h4 class="subtitle">
        智慧工厂涵盖了能源管理、砂石骨料一卡通、设备管理、车辆调度等多个领域
      </h4>
      <div class="case">
        <div class="case_img">
          <img src="../assets/smartFactory/shashi.png" alt="" />
        </div>
        <div class="case_introduce">
          <div>
            <div class="detail">
              <h3 class="detail_title">砂石骨料一卡通</h3>
              <h4 class="detail_subtitle">Sand aggregate card</h4>
              <h4 class="detail_list_title">砂石骨料一卡通解决方案</h4>
              <div class="list">
                <div class="item">
                  <div class="dot_big">
                    <div class="dot_small"></div>
                  </div>
                  <div>对砂石骨料等产品整体营销流程，进行智能化管理；</div>
                </div>
                <div class="item">
                  <div class="dot_big dot_big_1">
                    <div class="dot_small"></div>
                  </div>
                  <div>通过在线下单、自助取货，简化客户采购流程，提高效率；</div>
                </div>
                <div class="item">
                  <div class="dot_big dot_big_2">
                    <div class="dot_small"></div>
                  </div>
                  <div>智能化硬件与软件的应用，减少人为操作的潜在问题，降低风险；</div>
                </div>
                <div class="item">
                  <div class="dot_big dot_big_3">
                    <div class="dot_small"></div>
                  </div>
                  <div>数据实时采集以及对营销流程的综合分析，便于高效管理。</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="btn">
        <button type="button">更多解决方案</button>
      </div> -->
    </div>
    <div id="device" class="smart_factory">
      <h2 class="title">设备管理解决方案</h2>
      <h4 class="subtitle">
        智慧工厂涵盖了能源管理、砂石骨料一卡通、设备管理、车辆调度等多个领域
      </h4>
      <div class="case">
        <div class="case_introduce">
          <div>
            <div class="detail">
              <h3 class="detail_title">设备管理</h3>
              <h4 class="detail_subtitle">Device management</h4>
              <h4 class="detail_list_title">设备管理解决方案</h4>
              <div class="list">
                <div class="item">
                  <div class="dot_big">
                    <div class="dot_small"></div>
                  </div>
                  <div>对设备进行全方位的实时监测，并及时反馈潜在风险；</div>
                </div>
                <div class="item">
                  <div class="dot_big dot_big_1">
                    <div class="dot_small"></div>
                  </div>
                  <div>融合多种设备问题的针对性解决方案，提高设备维护效率；</div>
                </div>
                <div class="item">
                  <div class="dot_big dot_big_2">
                    <div class="dot_small"></div>
                  </div>
                  <div>对厂区设备的各个指标进行综合性分析，提高管理效率。</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="case_img">
          <img src="../assets/smartFactory/device.png" alt="" />
        </div>
      </div>
      <!-- <div class="btn">
        <button type="button">更多解决方案</button>
      </div> -->
    </div>
    <div id="car" class="smart_factory smart_city">
      <h2 class="title">车辆调度解决方案</h2>
      <h4 class="subtitle">
        智慧工厂涵盖了能源管理、砂石骨料一卡通、设备管理、车辆调度等多个领域
      </h4>
      <div class="case">
        <div class="case_img">
          <img src="../assets/smartFactory/car.png" alt="" />
        </div>
        <div class="case_introduce">
          <div>
            <div class="detail">
              <h3 class="detail_title">车辆调度</h3>
              <h4 class="detail_subtitle">Vehicle scheduling</h4>
              <h4 class="detail_list_title">车辆调度解决方案</h4>
              <div class="list">
                <div class="item">
                  <div class="dot_big">
                    <div class="dot_small"></div>
                  </div>
                  <div>车辆行驶路线、油耗等数据的实时监测，并进行异常警报；</div>
                </div>
                <div class="item">
                  <div class="dot_big dot_big_1">
                    <div class="dot_small"></div>
                  </div>
                  <div>
                    对车辆以及人员进行任务调度管理，提高车辆利用率，降低非必要能耗；
                  </div>
                </div>
                <div class="item">
                  <div class="dot_big dot_big_2">
                    <div class="dot_small"></div>
                  </div>
                  <div>通过大屏监控的实时数据分析，及时反馈现场问题，提高管理效率。</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="btn">
        <button type="button">更多解决方案</button>
      </div> -->
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
//页面引入swiper
// import { swiper, swiperSlide } from "vue-awesome-swiper";
// import "swiper/swiper-bundle.css";
import $ from "jquery";
import Footer from "../components/footer";
// import Swiper from "swiper";
// import "swiper/dist/css/swiper.min.css";
import Header from "../components/header";
// import { historyList } from "../utils/text1";
// import Footer from "../components/footer.vue";

export default {
  name: "SmartFactory",
  components: {
    // swiper,
    // swiperSlide,
    Header,
    Footer,
  },
  data() {
    return {
      // workmanshipList:[3,2],
      isChange: false,
    };
  },
  mounted() {
    // this.swiper1.slideTo(2, 1000, false)
    var data = this.$route.query.data;
    console.log(data);
    if (data) {
      this.jump(data);
    }
    // var self = this;
    // this.$nextTick(() => {
    //   setTimeout(() => {
    //     var stStart = $(window).scrollTop() + 74;
    //     var target = $("#bannerImg").height();
    //     if (stStart > target) {
    //       // $('#menu').addClass('active')
    //       self.isChange = true;
    //       // // console.log(self.isChange);
    //     } else {
    //       // $('#menu').removeClass('active')
    //       self.isChange = false;
    //       // // console.log(self.isChange);
    //     }
    //     console.log(target);
    //     $(window).scroll(function () {
    //       var st = $(window).scrollTop() + 74;

    //       if (st > target) {
    //         // $('#menu').addClass('active')
    //         self.isChange = true;
    //         // // console.log(self.isChange);
    //       } else {
    //         // $('#menu').removeClass('active')
    //         self.isChange = false;
    //         // // console.log(self.isChange);
    //       }
    //     });
    //   }, 100);
    // });
  },

  methods: {
    jump(data) {
      this.$nextTick(() => {
        setTimeout(() => {
          //获取指定的div盒
          let targetbox;
          if (data == 0) {
            targetbox = $("#energy");
          } else if (data == 1) {
            targetbox = $("#sand");
          } else if (data == 2) {
            targetbox = $("#device");
          } else if (data == 3) {
            targetbox = $("#car");
          } else {
            console.log("targetbox");
          }

          var valOfScroll = targetbox.offset().top - 100;
          //让滚轴从当前位置的scroll在600毫秒内偏移到位置为valOfScroll。
          $("html,body").animate({ scrollTop: valOfScroll }, 600);
          // targetbox.scrollIntoView({
          //   behavior: "smooth", // 平滑过渡
          //   // block: "start", // 上边框与视窗顶部平齐。默认值
          // });
        });
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.smart_factory_content {
  .cooperative_partner {
    width: 100%;
    height: 502px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .title {
      font-size: 34px;
      color: #333333;
      margin-bottom: 22px;
    }
    .subtitle {
      font-size: 14px;
      color: #999999;
      margin-bottom: 60px;
    }
  }
  .about {
    width: 100%;
    height: 410px;
    background-image: url("../assets/homeImg/about.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .title {
      font-size: 34px;
      color: #fff;
      margin-bottom: 60px;
    }
    .text {
      font-size: 16px;
      color: #ffffff;
      line-height: 28px;
      text-align: justify;
      width: 1200px;
      margin-bottom: 60px;
    }
  }
}
</style>
